import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusOutlined, LoadingOutlined, QuestionCircleOutlined, DownOutlined, ProfileOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, onBeforeUnmount, watch } from 'vue';
import { selectEmployeeByPagination, selectJobByParam, insertHyEmployee, selectEmployee, deleteHyEmployee, uploadBatch, updateHyEmployee, selectDepartEmployee, selectIdCardMessage, updateResetPwd, isNeedHandOver, selectDepartmentList, selectRankList, selectWorkPlaceByList, isSuperiorList, insertHistoryPosition, updateHistoryPosition, deleteHistoryPosition, selectHistoryPositionList, selectOrganization } from '@/api/personnel';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import upload from '@/components/upload.vue';
import uploadExl from '@/components/uploadExl.vue';
import getFieldRules from '@/utils/fieldRules';
import { selectJobCascade } from '@/api/material';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import moment from 'moment'; //表头

const columns = [{
  title: '姓名',
  dataIndex: 'userName',
  width: 100,
  fixed: 'left'
}, {
  title: '性别',
  dataIndex: 'sex',
  width: 60,
  align: 'center',
  slots: {
    customRender: 'sex'
  }
}, {
  title: '身份证号',
  dataIndex: 'idCard',
  width: 200,
  align: 'center'
}, {
  title: '手机号',
  dataIndex: 'userMobile',
  width: 130,
  align: 'center'
}, {
  title: '部门',
  dataIndex: 'departmentName'
}, // {
//   title: '是否购买保险',
//   dataIndex: 'isHaveInsurance',
//   slots: {
//     customRender: 'isHaveInsurance',
//   },
// },
{
  title: '人员岗位',
  dataIndex: 'jobNo',
  slots: {
    customRender: 'jobNo'
  }
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '人员状态',
  dataIndex: 'isOnjob',
  slots: {
    customRender: 'isOnjob'
  }
}, {
  title: '档案是否完整',
  dataIndex: 'isComplete',
  slots: {
    customRender: 'isComplete'
  }
}, {
  title: '是否参与考勤',
  dataIndex: 'isInAttend',
  slots: {
    customRender: 'isInAttend'
  }
}, {
  title: '与面板机是否同步',
  dataIndex: 'isSynchronization',
  slots: {
    customRender: 'isSynchronization'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 330,
  slots: {
    customRender: 'operation'
  }
}];
const columnsPosition = [{
  title: '时间',
  dataIndex: 'data',
  slots: {
    customRender: 'data'
  }
}, {
  title: '部门',
  dataIndex: 'departmentId',
  slots: {
    customRender: 'departmentId'
  }
}, {
  title: '岗位',
  dataIndex: 'jobId',
  slots: {
    customRender: 'jobId'
  }
}, {
  title: '职级',
  dataIndex: 'rankId',
  slots: {
    customRender: 'rankId'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 130,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    LoadingOutlined,
    PlusOutlined,
    upload,
    uploadExl,
    QuestionCircleOutlined,
    DownOutlined,
    ProfileOutlined,
    PaperClipOutlined,
    uploadImgs
  },

  setup() {
    const formRef = ref();
    const formRef1 = ref();
    const visible = ref(false);
    const setId = ref('');
    const formState = reactive({
      userName: '',
      idCard: '',
      userMobile: '',
      jobNo: [],
      // isHaveInsurance: null,
      isComplete: null,
      isOnjob: null,
      workNo: null,
      departmentId: null,
      papersName: null
    });
    const tableData = ref([]); //查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const state = reactive({
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    }; //人员详情


    const getPersonnelInfo = async key => {
      const res = await selectEmployee({
        idCard: key
      });

      if (res.code === 200) {
        // console.log(res.data[0])
        Object.assign(formState1, res.data[0]);
        formState1.isAdmin = res.data[0].isAdmin === 0 ? false : true;
        formState1.isHaveInsurance = res.data[0].isHaveInsurance === 0 ? false : true;
        formState1.isBlackList = res.data[0].isBlackList === 0 ? false : true;
        formState1.isInAttend = res.data[0].isInAttend === 0 ? false : true;
        formState1.isInAttend = res.data[0].isInAttend === 0 ? false : true;
        formState1.isLeaveIncrease = res.data[0].isLeaveIncrease === 0 ? false : true;
        formState1.resumeUrl = res.data[0].resumeUrl ? JSON.parse(res.data[0].resumeUrl) : [];
        formState1.otherCard = JSON.parse(res.data[0].otherCard);
        chartInfo.workNo = res.data[0].workNo;
        chartInfo.userName = res.data[0].userName;
        formState1.otherCard = formState1.otherCard.filter(item => item.otherCardName != null);
      } else {
        _message.error(res.message);
      }
    }; //查看


    const chartInfo = {
      workNo: null,
      userName: null
    };

    const toView = record => {
      // console.log(key)
      getIsSuperiorList(record.uid);
      modalNum.value = 0;
      title.value = '人员详情';
      visible.value = true;
      const obj = tableData.value.find(item => {
        return item.key == record.key;
      });
      getPersonnelInfo(obj.idCard);
    }; //修改


    const setPersonnelInfo = async () => {
      if (!handOver.value.length && isShowHandOver.value) {
        _message.error('请选择交接人');

        return;
      } // console.log(formState1)


      const param = {};
      Object.assign(param, formState1);
      param.isAdmin = setIsAdmin(formState1.isAdmin);
      param.isHaveInsurance = formState1.isHaveInsurance ? 1 : 0;
      param.isBlackList = formState1.isBlackList ? 1 : 0;
      param.isInAttend = formState1.isInAttend ? 1 : 0;
      param.isLeaveIncrease = formState1.isLeaveIncrease ? 1 : 0;
      param.otherCard = JSON.stringify(formState1.otherCard);
      param.resumeUrl = JSON.stringify(formState1.resumeUrl);
      const res = await updateHyEmployee(param);

      if (res.code === 200) {
        _message.success(res.message);

        visible.value = false;
        isShowHandOver.value = false;
        handOver.value = [];
        formRef1.value.resetFields();
        IDNumber.value = null;
        getTableData();
      } else if (res.code === 10000) {
        _message.warning(res.message);

        visible.value = false;
        isShowHandOver.value = false;
        handOver.value = [];
        formRef1.value.resetFields();
        IDNumber.value = null;
        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const modification = (record, val) => {
      // console.log(key)
      getIsSuperiorList(record.uid);
      modalNum.value = 2;

      if (val === 1) {
        title.value = '同步数据确认';
      } else {
        title.value = '人员详情';
      }

      visible.value = true;
      const obj = tableData.value.find(item => {
        return item.key == record.key;
      });
      getPersonnelInfo(obj.idCard);
      setId.value = record.key;
    }; //删除


    const deletePersonnel = async val => {
      const res = await deleteHyEmployee(val);

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const onDelete = key => {
      // const obj = tableData.value.find(item => {
      //   return item.key == key
      // })
      deletePersonnel({
        ids: String(key)
      });
    }; //批量删除


    const batchDelete = () => {
      let str = '';
      state.selectedRowKeys.forEach(item => {
        tableData.value.forEach(ixt => {
          if (item === ixt.key) {
            str += `${ixt.id},`;
          }
        });
      }); // console.log(str.substr(0, str.length - 1))

      deletePersonnel({
        ids: str.substr(0, str.length - 1)
      });
    }; ///////////////


    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination();
    const loading = ref(false);
    const rowsData = reactive({
      leaveJobCount: 0,
      onJobCount: 0,
      transferJobCount: 0
    }); // 获取人员列表

    const getTableData = async () => {
      loading.value = true;
      const param = {
        userName: formState.userName,
        idCard: formState.idCard,
        userMobile: formState.userMobile,
        jobNo: formState.jobNo.length ? formState.jobNo[1] : '',
        // isHaveInsurance: formState.isHaveInsurance !== null ? Number(formState.isHaveInsurance): null,
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        isComplete: formState.isComplete !== null ? Number(formState.isComplete) : null,
        isOnjob: formState.isOnjob !== null ? Number(formState.isOnjob) : null,
        isSynchronization: formState.isSynchronization !== null ? Number(formState.isSynchronization) : null,
        workNo: formState.workNo,
        departmentId: formState.departmentId,
        papersName: formState.papersName
      };
      let res = await selectEmployeeByPagination(param); // console.log(res)

      if (res.code === 200) {
        var _res$data, _res$data$list;

        tableData.value = [];
        res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$list = _res$data.list) === null || _res$data$list === void 0 ? void 0 : _res$data$list.forEach((item, index) => {
          item.jobNo = [item.isAdmin, item.jobNo];
          tableData.value.push({ ...item,
            key: item.id
          });
        });
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;

        if (res.data.rows.length) {
          rowsData.onJobCount = res.data.rows[0].onJobCount;
          rowsData.leaveJobCount = res.data.rows[0].leaveJobCount;
          rowsData.transferJobCount = res.data.rows[0].transferJobCount;
        }

        loading.value = false;
      } else {
        _message.error(res.message);

        loading.value = false;
      }
    }; //人员岗位


    const jobsData = ref([{
      value: 1,
      label: '管理人员',
      children: []
    }, {
      value: 0,
      label: '施工人员',
      children: []
    }]);

    const getJobData = async () => {
      let res = await selectJobByParam({
        jobName: '',
        jobType: ''
      });

      if (res.code === 200) {
        let arr = [];
        res.data.forEach(element => {
          arr.push({
            value: element.id,
            label: element.jobName,
            jobType: element.jobType
          });
        });
        jobsData.value.forEach(item => {
          if (item.value === 1) {
            item.children = arr.filter(ele => {
              return ele.jobType === 1;
            });
          } else {
            item.children = arr.filter(ele => {
              return ele.jobType === 0;
            });
          }
        }); // console.log(jobsData)
      } else {
        _message.error(res.message);
      }
    }; //表格显示人员岗位


    const nameByPositions = txt => {
      // console.log(txt)
      if (txt) {
        const obj = jobsData.value.find(key => {
          return key.value === txt[0];
        });

        if (obj.children.length) {
          const obj1 = obj.children.find(key => {
            return key.value === txt[1];
          });
          return obj1 ? obj1.label : null;
        }
      }
    }; //弹窗相关


    const formState1 = reactive({
      userName: '',
      sex: 0,
      idCard: '',
      userMobile: '',
      icon: '',
      cardFront: '',
      cardBack: '',
      otherCard: [{
        otherCardUrl: "",
        otherCardName: null
      }],
      isAdmin: false,
      address: '',
      isHaveInsurance: null,
      jobNo: [],
      salaryType: 1,
      isOnjob: null,
      isBlackList: false,
      comment: null,
      salary: "",
      uid: "",
      isAdmin: false,
      // firstWorkingDate:undefined,
      hireDate: undefined,
      startEnjoyDate: undefined,
      workNo: null,
      departmentId: null,
      rankId: null,
      workPlaceId: null,
      parentId: null,
      companyEmail: null,
      seatNo: null,
      resumeUrl: [],
      everyAnnualDay: null,
      isLeaveIncrease: false,
      targetAnnualSalary: null
    });
    const title = ref('人员详情');
    const modalNum = ref(0); //0-查看，1-新增，2-修改

    const handleAdd = () => {
      modalNum.value = 1;
      title.value = '新增人员';
      getIsSuperiorList(null);
      formState1.menuGenre = Number(Cookies.get("menuGenre"));
      formState1.cardFront = "";
      formState1.cardBack = "";
      formState1.otherCard = [{
        otherCardUrl: "",
        otherCardName: null
      }], formState1.targetAnnualSalary = null; // console.log(formState1)

      visible.value = true;
    }; //接受upload子组件传过来的值
    //


    const headerImgUrl = val => {
      // console.log(11112222,val)
      formState1.icon = val;
    };

    const cardFrontImgUrl = val => {
      // console.log(11112222,val)
      formState1.cardFront = val;
    };

    const cardBackImgUrl = val => {
      // console.log(11112222,val)
      formState1.cardBack = val;
    };

    const otherCardUrl = (val, index) => {
      console.log(11112222, val, index);
      formState1.otherCard[index].otherCardUrl = val;
      console.log(formState1);
    };

    const getJob = val => {
      const obj = jobsData.value.find(key => {
        return key.value == val;
      });
      return obj.children;
    };

    const isSuccess = val => {
      getTableData();
    }; //字段rules


    const {
      checkName,
      checkPhone,
      checkCard,
      validatePass,
      checkJob
    } = getFieldRules();
    const rules = {
      userName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      sex: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      idCard: [{
        required: true,
        validator: checkCard,
        trigger: 'blur'
      }],
      workNo: [{
        required: true,
        message: '工号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      // cardFront: [
      //   {
      //     // required: true,
      //     message: '请上传身份证正面',
      //     trigger: 'blur',
      //   },
      // ],
      // cardBack: [
      //   {
      //     // required: true,
      //     message: '请上传身份证正面',
      //     trigger: 'blur',
      //   },
      // ],
      userMobile: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }],
      address: [{
        required: true,
        message: '请输入地址',
        trigger: 'blur'
      }],
      // icon: [
      //   {
      //     // required: true,
      //     validator: validatePass,
      //     trigger: 'change',
      //   },
      // ],
      isHaveInsurance: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      isAdmin: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      isInAttend: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      jobNo: [{
        required: true,
        validator: checkJob,
        trigger: 'change'
      }],
      salaryType: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      salary: [{
        required: true,
        validator: checkJob,
        trigger: 'blur'
      }],
      comment: [{
        required: true,
        message: '请输入加入黑名单理由',
        trigger: 'blur'
      }],
      // firstWorkingDate: [
      //   {
      //     required: true,
      //     message: '初次工作时间不能为空',
      //     trigger: 'change',
      //     type: 'string',
      //   },
      // ],
      hireDate: [{
        required: true,
        message: '员工入职时间不能为空',
        trigger: 'change',
        type: 'string'
      }],
      levelTime: [{
        required: true,
        message: '员工离职时间不能为空',
        trigger: 'change',
        type: 'string'
      }],
      startEnjoyDate: [{
        required: true,
        message: '开始享用企业带薪时间不能为空',
        trigger: 'change',
        type: 'string'
      }],
      departmentId: [{
        required: true,
        message: '所属部门不能为空',
        trigger: 'change',
        type: 'number'
      }],
      parentId: [{
        required: true,
        message: '上级不能为空',
        trigger: 'change'
      }],
      everyAnnualDay: [{
        required: true,
        message: '每年年假天数不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      isLeaveIncrease: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }]
    };

    const handleCancel = () => {
      visible.value = false;
      formRef1.value.resetFields();
    }; //新增人员


    const addPersonnel = async () => {
      const param = {};
      Object.assign(param, formState1);
      param.isAdmin = setIsAdmin(formState1.isAdmin);
      param.isHaveInsurance = formState1.isHaveInsurance ? 1 : 0;
      param.isBlackList = formState1.isBlackList ? 1 : 0;
      param.isInAttend = formState1.isInAttend ? 1 : 0;
      param.otherCard = JSON.stringify(formState1.otherCard);
      param.resumeUrl = JSON.stringify(formState1.resumeUrl);
      param.isLeaveIncrease = formState1.isLeaveIncrease ? 1 : 0;
      const res = await insertHyEmployee(param);

      if (res.code === 200) {
        _message.success(res.message);

        visible.value = false;
        formRef1.value.resetFields();
        IDNumber.value = null;
        getTableData();
      } else if (res.code === 10000) {
        _message.warning(res.message);

        visible.value = false;
        formRef1.value.resetFields();
        IDNumber.value = null;
        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const handleOk = () => {
      formRef1.value.validate().then(() => {
        // if(new Date(formState1.firstWorkingDate)>new Date(formState1.hireDate)){
        //   message.error('初次工作时间不能大于员工入职时间')
        //   return
        // }
        if (new Date(formState1.startEnjoyDate) < new Date(formState1.hireDate)) {
          _message.error('开始享用企业带薪假时间不能小于员工入职时间');

          return;
        }

        if (modalNum.value === 1) {
          addPersonnel();
        } else {
          setHandOver();
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //从离职库搜索人员信息


    const IDNumber = ref('');

    const selectDepartEmployee1 = async () => {
      const res = await selectDepartEmployee({
        idCard: IDNumber.value
      });

      if (res.code === 200) {
        if (res.data.length) {
          _message.success(res.message);

          Object.assign(formState1, res.data[0]);
          formState1.isAdmin = res.data[0].isAdmin === 0 ? false : true;
          formState1.isHaveInsurance = res.data[0].isHaveInsurance === 0 ? false : true;
          formState1.isBlackList = res.data[0].isBlackList === 0 ? false : true;
          formState1.isInAttend = res.data[0].isInAttend === 0 ? false : true;
          formState1.resumeUrl = JSON.parse(res.data[0].resumeUrl);
          formState1.otherCard = JSON.parse(res.data[0].otherCard);
          formState1.isLeaveIncrease = res.data[0].isLeaveIncrease === 0 ? false : true;
        } else {
          _message.error('未找到此人员');
        }
      } else {
        _message.error(res.message);
      }
    };

    const onSearch = () => {
      selectDepartEmployee1();
    }; //点击分页


    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; //通过身份证上传


    const cardFrontImgUrl1 = idCardUrl => {
      // console.log(idCardUrl)
      if (idCardUrl) {
        getIdCardMessage(idCardUrl);
      }
    };

    const getIdCardMessage = async idCardUrl => {
      const res = await selectIdCardMessage({
        idCardUrl
      });

      if (res.code === 200) {
        // console.log(res.data)
        handleAdd();
      } else {
        _message.error(res.message);
      }
    }; //重置密码


    const resetPassword = async id => {
      const res = await updateResetPwd({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    }; //屏幕宽度


    const getClientWidth = ref(0);

    const getClientWidthFn = () => {
      if (document.body.clientWidth > 1440) {
        getClientWidth.value = '490px';
      } else {
        getClientWidth.value = '540px';
      }
    }; //员工交接


    const handOver = ref([]);
    const isShowHandOver = ref(false); //岗位及人员

    const optionsForHandOver = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        const jobProjects = res.data.jobProjects; //本项目管理岗集合

        console.log(jobProjects);
        optionsForHandOver.value = conversion(jobProjects); // console.log(optionsForHandOver.value)
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            //剔除当前要离职的人
            if (ixt.uid !== formState1.uid) {
              obj.children.push({
                value: `${ixt.uid}`,
                label: ixt.userName,
                projectId: ixt.belongProject
              });
            }
          });
        } //除去岗位下没有人员的岗位


        if (obj.children.length) {
          arr.push(obj);
        }
      });
      return arr;
    }; //人员是否需要交接


    const setHandOver = async val => {
      const param = {
        idCard: formState1.uid,
        // newIdCard:handOver.value[1]
        isOnjob: formState1.isOnjob
      };
      const res = await isNeedHandOver(param);

      if (res.code === 200) {
        //true需要交接
        if (res.data) {
          getJobCascade();
          isShowHandOver.value = true;
        } else if (!res.data) {
          setPersonnelInfo();
        }
      } else {
        _message.error(res.message);
      }
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const belongProject = Cookies.get('belongProject'); //部门信息

    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //新增删除其他证件


    const addOtherCard = () => {
      formState1.otherCard.push({
        otherCardUrl: null,
        otherCardName: null
      });
    };

    const deleteOtherCard = index => {
      formState1.otherCard.splice(index, 1);
    };

    const menuGenre = Number(Cookies.get("menuGenre")); //职级信息

    const rankData = ref([]);

    const getRankList = async () => {
      let res = await selectRankList();

      if (res.code === 200) {
        rankData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.rankName
            };
            rankData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //工作地点信息


    const workPlaceData = ref([]);

    const getWorkPlaceByList = async () => {
      let res = await selectWorkPlaceByList();

      if (res.code === 200) {
        workPlaceData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.workPlaceName
            };
            workPlaceData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //上级


    const parentData = ref([]);

    const getIsSuperiorList = async uid => {
      let res = await isSuperiorList({
        uid,
        belongProject: Number(Cookies.get('belongProject'))
      });

      if (res.code === 200) {
        parentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.uid,
              label: item.userName
            };
            parentData.value.push(obj);
          });
        }

        parentData.value.unshift({
          value: '无',
          label: '无'
        });
      } else {
        _message.error(res.message);
      }
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState1.resumeUrl = val;
    }; //年龄


    const discriCard = computed(() => {
      let cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
      if (!cardReg.test(formState1.idCard)) return;
      (formState1.idCard + '').substring(6, 10) + "-" + (formState1.idCard + '').substring(10, 12) + "-" + (formState1.idCard + '').substring(12, 14); //获取年龄 

      let myDate = new Date();
      let month = myDate.getMonth() + 1;
      let day = myDate.getDate();
      let age = myDate.getFullYear() - (formState1.idCard + '').substring(6, 10) - 1;

      if ((formState1.idCard + '').substring(10, 12) < month || (formState1.idCard + '').substring(10, 12) == month && (formState1.idCard + '').substring(12, 14) <= day) {
        age++;
      }

      return age;
    }); //历史职位信息

    const positionVisible = ref(false);

    const handleToViewPosition = async () => {
      let res = await selectHistoryPositionList({
        uid: formState1.uid
      });

      if (res.code === 200) {
        tablePositionData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            tablePositionData.value.push({ ...item,
              loading: false,
              data: [item.startDay, item.endDay]
            });
          });
        }

        positionVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const tablePositionData = ref([// {
      //   belongProject: 10039,
      //   createTime: "2022-11-09",
      //   departmentId: 8,
      //   departmentName: "门市部",
      //   endDay: 1665187200000,
      //   // id: 1,
      //   jobId: 233,
      //   jobName: "安全员",
      //   parentName: null,
      //   rankId: 4,
      //   rankName: "职级1",
      //   startDay: 1662595200000,
      //   uid: "430151955726479360"
      // }
    ]);

    const handleAddPosition = () => {
      tablePositionData.value.push({
        data: [],
        departmentId: null,
        jobId: null,
        rankId: null,
        id: null,
        loading: false
      });
    };

    const savePosition = async val => {
      console.log(val); // val.isSet = false

      if (!val.data.length) {
        _message.error('时间不能为空');

        return;
      }

      if (val.departmentId === null) {
        _message.error('部门不能为空');

        return;
      }

      if (val.jobId === null) {
        _message.error('岗位不能为空');

        return;
      }

      if (val.rankId === null) {
        _message.error('职级不能为空');

        return;
      }

      let isOk = false;
      let arr = [];
      tablePositionData.value.forEach(item => {
        arr.push(item.data);
      });
      isOk = findDataIsOk(arr);
      console.log(isOk);

      if (isOk) {
        _message.error('时间不能有重合');

        return;
      }

      const param = {
        uid: formState1.uid,
        startDay: val.data[0],
        endDay: val.data[1],
        departmentId: val.departmentId,
        jobId: val.jobId,
        rankId: val.rankId
      };
      val.loading = true;

      try {
        let res = await insertHistoryPosition(param);

        if (res.code === 200) {
          val.loading = false;
          val.id = res.data.id;

          _message.success(res.message);
        } else {
          val.loading = false;

          _message.error(res.message);
        }
      } catch (error) {
        val.loading = false;
        console.log(error);
      }
    };

    const modifyPosition = async val => {
      if (!val.data.length) {
        _message.error('时间不能为空');

        return;
      }

      if (val.departmentId === null) {
        _message.error('部门不能为空');

        return;
      }

      if (val.jobId === null) {
        _message.error('岗位不能为空');

        return;
      }

      if (val.rankId === null) {
        _message.error('职级不能为空');

        return;
      }

      let isOk = false;
      let arr = [];
      tablePositionData.value.forEach(item => {
        arr.push(item.data);
      });
      isOk = findDataIsOk(arr); // console.log(isOk)

      if (isOk) {
        _message.error('时间不能有重合');

        return;
      }

      const param = {
        id: val.id,
        startDay: val.data[0],
        endDay: val.data[1],
        departmentId: val.departmentId,
        jobId: val.jobId,
        rankId: val.rankId
      };
      val.loading = true;

      try {
        let res = await updateHistoryPosition(param);

        if (res.code === 200) {
          val.loading = false;

          _message.success(res.message);
        } else {
          val.loading = false;

          _message.error(res.message);
        }
      } catch (error) {
        val.loading = false;
        console.log(error);
      }
    }; //架构图


    const chartVisible = ref(false);
    const chartData = ref([]);

    const getChartData = async (workNo, userName) => {
      const param = {
        workNo,
        userName
      };
      let res = await selectOrganization(param);

      if (res.code === 200) {
        chartData.value = [];

        if (res.data && res.data.length) {
          chartData.value = setTreeData(res.data);
        }

        chartVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const setTreeData = arr => {
      let treeData = [];
      arr.forEach(item => {
        let obj = {
          id: item.workNo,
          label: item.userName
        };

        if (item.current) {
          Object.assign(chartData, item);
          obj.style = {
            "color": "#fff",
            "background": "#108ffe"
          };
        }

        if (item.childrenList && item.childrenList.length) {
          obj.children = setTreeData(item.childrenList);
        }

        treeData.push(obj);
      });
      return treeData;
    };

    const handleToViewChart = () => {
      getChartData(chartInfo.workNo, chartInfo.userName);
    };

    const onNodeClick = (e, data) => {
      console.log(e, data);
    };

    const onDeletePosition = async (id, index) => {
      if (!id) {
        tablePositionData.value.splice(index, 1);
      } else {
        let res = await deleteHistoryPosition({
          id
        });

        if (res.code === 200) {
          _message.success(res.message);

          tablePositionData.value.splice(index, 1); // handleToViewPosition()
        } else {
          _message.error(res.message);
        }
      }
    };

    const setIsAdmin = val => {
      if (menuGenre === 1) {
        return 1;
      } else {
        return val ? 1 : 0;
      }
    };

    const disabledDate1 = current => {
      return current && current > moment().subtract(1, 'days');
    };

    const findDataIsOk = times => {
      for (let i = 0; i < times.length; i++) {
        for (let j = 0; j < times.length; j++) {
          let is = moment(times[i][0]).valueOf();
          let ie = moment(times[i][1]).valueOf();
          let js = moment(times[j][0]).valueOf();
          let je = moment(times[j][1]).valueOf();

          if (i == j) {
            if (is == ie) {
              return true;
            } else {
              continue;
            }
          } else {
            if (is <= je && is >= js || ie >= js && ie <= je || is <= js && ie >= je || is >= js && ie <= je) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }; // watch(()=>formState1.isAdmin, (val)=> {
    //   if(formState1.isAdmin){
    //     formState1.salary = null
    //   }
    // })


    onMounted(() => {
      getClientWidthFn();

      window.onresize = function () {
        getClientWidthFn();
      };

      getJobData();
      getTableData();
      getDepartmentList();
      getRankList();
      getWorkPlaceByList();
    });
    onBeforeUnmount(() => {
      window.onresize = null;
    });
    return {
      formRef,
      visible,
      formState,
      jobsData,
      tableData,
      columns,
      hasSelected,
      ...toRefs(state),
      // func
      onSubmit,
      resetForm,
      onSelectChange,
      batchDelete,
      toView,
      modification,
      onDelete,
      ////
      formState1,
      paginationConfig,
      nameByPositions,
      title,
      modalNum,
      handleOk,
      handleAdd,
      getJob,
      rules,
      formRef1,
      handleCancel,
      /////
      upload,
      headerImgUrl,
      cardBackImgUrl,
      cardFrontImgUrl,
      otherCardUrl,
      //
      uploadExl,
      QuestionCircleOutlined,
      IDNumber,
      onSearch,
      isSuccess,
      onPageChange,
      cardFrontImgUrl1,
      resetPassword,
      getClientWidth,
      ...toRefs(rowsData),
      ////
      handOver,
      optionsForHandOver,
      isShowHandOver,
      setHandOver,
      setPersonnelInfo,
      disabledDate,
      belongProject,
      ////
      departmentData,
      addOtherCard,
      deleteOtherCard,
      menuGenre,
      rankData,
      workPlaceData,
      getAdjuncts,
      parentData,
      discriCard,
      positionVisible,
      handleToViewPosition,
      columnsPosition,
      tablePositionData,
      handleAddPosition,
      savePosition,
      modifyPosition,
      chartData,
      handleToViewChart,
      onNodeClick,
      onDeletePosition,
      chartVisible,
      disabledDate1
    };
  }

});